<template>
  <form class="fill-height">
    <v-container fluid class="fill-height">
      <v-row class="d-flex flex-row-reverse fill-height">
        <v-col cols="12" md="3" class="flex-grow-1 overflow-y-auto fill-height">
          <v-row>
            <v-col cols="12" v-if="itemId">
              <AuditLog
                :url="`app/content-status/${itemId}/changes`"
                class="my-1 justify-end"
                :creationTime="creationTime"
                creationUsername="admin"
                :lastModificationTime="lastModificationTime"
                lastModificationUsername="admin"
                :colsName="{
                  Name: 'الاسم',
                }"
              />
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12">
              <h1>بيانات اساسية</h1>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <SlugBase v-model="slug" :foundedSlug="foundedSlug" />
            </v-col>
          </v-row>
        </v-col>

        <v-col cols="12" md="9" class="flex-grow-1 overflow-y-auto fill-height">
          <v-card elevation="0">
            <!-- TABS HEADER -->
            <v-tabs
              show-arrows
              background-color="indigo"
              dark
              v-model="tab"
              align-with-title
              fixed-tabs
              height="45px"
              class="mb-4"
            >
              <v-tabs-slider color="white darken-1"></v-tabs-slider>
              <v-tab
                v-for="lang in languagesKeysValue"
                :key="lang.key"
                class="white--text font-weight-black"
                active-class="indigo lighten-2"
              >
                {{ lang.value }}
              </v-tab>
            </v-tabs>

            <!-- TABS CONTENT -->
            <v-tabs-items v-model="tab">
              <v-tab-item v-for="lang in languagesKeysValue" :key="lang.key">
                <v-card flat>
                  <v-container fluid>
                    <v-row>
                      <v-col cols="12">
                        <v-text-field
                          v-model="formLanguage[`${lang.key}`][`name`]"
                          :counter="50"
                          :label="'الاسم بالـ ' + lang.value"
                          outlined
                          hide-details="auto"
                          dense
                        />
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card>
              </v-tab-item>
            </v-tabs-items>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </form>
</template>

<script>
import { languagesKeys, languagesKeysValue } from '@/config/config';
import { validationMixin } from 'vuelidate';
import { required, maxLength, email } from 'vuelidate/lib/validators';
import {
  showSnackbarFailMessage,
  showSnackbarSuccessMessage,
  showSnackbarWarningMessage,
} from '@/utls/snackbar';
import { makeid } from '@/utls/jsCommon';
import AuditLog from '@/components/auditLog/AuditLog.vue';
import SlugBase from '@/components/SlugBase.vue';
import { getErrorDetails } from '@/helper/commonjs';

const initData = () => ({
  formLanguage: { ...languagesKeys() }, // { ar:{ }, en:{ } }
  slug: '',
  tab: null,
  languagesKeysValue: languagesKeysValue(),
  creationTime: '',
  lastModificationTime: '',
  foundedSlug: '',
});

export default {
  props: [
    'submitWithClose',
    'submitWithNew',
    'submitWithoutClose',
    'setItemID',
    'afterSubmit',
    'closeDialog',
    'isNew',
    'isView',
    'isEdit',
    'itemId',
  ],
  mixins: [validationMixin],
  validations: {},
  data: () => ({ ...initData() }),
  async created() {
    if (this.isEdit || this.isView) {
      const responseData = await this.getById(this.itemId);
      if (responseData == null) return; // there is no data - error
      for (const k in this.$data) {
        const value = responseData[k];
        if (value == undefined) continue;
        this.$data[k] = value;
      }
      for (const k in this.$data.formLanguage) {
        const languageDetail = responseData.contentStatusLanguages.find(
          (x) => x.language == k,
        );
        if (languageDetail == undefined) continue;
        this.$data.formLanguage[k] = languageDetail;
      }
    } else {
      this.slug = makeid(15);
    }
  },
  computed: {
    inputLanguages() {
      const languages = this.languagesKeysValue.reduce((p, v) => {
        const { key, value } = v;
        const name = this.formLanguage[key].name;
        if (name) return [...p, { language: key, name }];
        return p;
      }, []);
      return languages;
    },
  },
  methods: {
    doSave(postData) {
      this.$store.commit('loading/SET_DIALOG_LOADING', true);
      return this.$http
        .post('app/content-status', { ...postData })
        .then((response) => {
          if (response.status != 200) return false;
          this.setItemID(response.data.id);
          this.creationTime = response.data.creationTime;
          this.lastModificationTime = response.data.lastModificationTime;
          showSnackbarSuccessMessage('تم الحفظ بنجاح');
          return true;
        })
        .catch(this.errorSaveHabdler)
        .finally(() => this.$store.commit('loading/SET_DIALOG_LOADING', false));
    },
    doUpdate(postData) {
      this.$store.commit('loading/SET_DIALOG_LOADING', true);
      return this.$http
        .put('app/content-status/' + this.itemId, { ...postData })
        .then((response) => {
          if (response.status != 200) return false;
          showSnackbarSuccessMessage('تم الحفظ بنجاح');
          return true;
        })
        .catch(this.errorSaveHabdler)
        .finally(() => this.$store.commit('loading/SET_DIALOG_LOADING', false));
    },
    errorSaveHabdler(error) {
      const { code, message } = getErrorDetails(error);

      if (code == 'Slug_Found') {
        this.foundedSlug = this.slug;
        return showSnackbarFailMessage(message);
      }

      return showSnackbarFailMessage(message ?? 'حدث خطأ اثناء عملية الحفظ');
    },
    clearInput() {
      const _initData = initData();
      for (const k in this.$data) this.$data[k] = _initData[k];
      this.slug = makeid(15);
    },
    isInvalid() {
      this.$v.$touch();
      if (this.$v.$invalid) return true;
      return false;
    },
    async getById(id) {
      this.$store.commit('loading/SET_DIALOG_LOADING', true);
      return this.$http
        .get('app/content-status/' + id, { params: { language: 'all' } })
        .then((response) => {
          const { data } = response;
          return data;
        })
        .catch((error) => {
          showSnackbarFailMessage('حدث خطأ اثناء عملية جلب البيانات');
          this.closeDialog(false, true);
          return null;
        })
        .finally(() => this.$store.commit('loading/SET_DIALOG_LOADING', false));
    },
    doSubmit(cb) {
      if (this.isInvalid()) {
        showSnackbarWarningMessage('يجب عدم ترك الاعمدة المطلوبة فارغة');
        return;
      }
      const slug = this.slug;
      const languages = this.inputLanguages;
      const postData = { slug, contentStatusLanguages: languages };
      if (this.isNew) {
        this.doSave(postData).then((status) => {
          if (!status) {
            showSnackbarFailMessage('حدث خطأ اثناء عملية الحفظ');
            return;
          }
          cb?.();
        });
      } else {
        // TODO : AH Need Refactor
        this.doUpdate(postData).then((status) => {
          if (!status) {
            showSnackbarFailMessage('حدث خطأ اثناء عملية الحفظ');
            return;
          }
          cb?.();
        });
      }
    },
  },
  watch: {
    submitWithClose() {
      this.doSubmit(() => {
        this.clearInput();
        this.afterSubmit();
        this.closeDialog(true);
      });
    },
    submitWithNew() {
      this.doSubmit(() => {
        this.setItemID(null);
        this.clearInput();
        this.afterSubmit();
      });
    },
    submitWithoutClose() {
      this.doSubmit(() => {
        this.afterSubmit();
      });
    },
  },
  components: { AuditLog, SlugBase },
};
</script>

<style lang="scss" scoped></style>
